@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: 'white',
}

img {
    max-width: 100%;
    max-height: 100%;
}

.opacity0 {
  opacity: 0;
  transition: 0.3s;
}

.opacity {
  opacity: 1;
  transition: 0.3s;
}

/** Edit this for the header **/
a:link, a:visited, a.active {
  font-family: 'Tahoma', sans-serif;
  color: #A8B7BC;
  transition: color 0.3s linear;
  text-decoration: none;
}
a:link:hover {
  color: white;
}

a:hover {
  color: white;
  text-decoration: none;
}

body {
  font-family: 'Tahoma', sans-serif;
  background: #161524;
}

html {
  scroll-behavior: smooth;
}

div#link_home {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    position: absolute;
    max-height: 50px;
    margin-left: 2%;
    margin-top: 15px;
    z-index:1;
}

@-webkit-keyframes bounce {
  10% { bottom: 2.5%; }
  50% { bottom: 3.5%; }
}

@keyframes bounce {
  10% { bottom: 2.5%; }
  50% { bottom: 3.5%; }
}

/***************************************/

footer {
  margin-top: 30px;
  text-align: center;
}

